import React from "react"
import { Link } from "gatsby"
import Container from "../components/container.js"

export default () => (
    <Container>
        <script
          src                       = "https://font.realtype.jp/api/script/v4"
          data-rt-user              = "Utwaj57YriSDqrfxwdojuAiQDVb70bcf"
        ></script>
        <header>
            <nav className='anotherHeader'>
              <Link to="/" className='off'><div className='backToTopSign'></div></Link>
              <Link to="/" className='hoverOff'>ホーム</Link>
            </nav>
        </header>
        <section id='404' className='area'>
            <h1 className='headline'>Page not found.</h1>
            <p>お探しのページは削除されたか、移動しました。</p>
            <Link to="/" className="external">ホームに戻る</Link>
        </section>
    </Container>
)

